require('./bootstrap');
import IMask from 'imask';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

var moment = window.moment = require('moment');

IMask(document.querySelector('.phone'), { mask: '\\0 (000) 000 00 00' });
IMask(document.querySelector('.id_number'), { mask: '00000000000', min: 11, max: 11 });
